import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_VALUE } from 'constants/global';

const initialState = {
  store: null,
  device: null,
  deviceInfo: null,
  startTime: null,
  duration: 30,
  loading: false,
  bookmarks: [],
  transcript: null,
  data: null,
  searchMode: '2',
  soMode: 'Phone number',
  phone: null,
  soDate: null,
  soID: '',
  soCandidates: [],
  candidate: null,
  openListDevice: true,
  filterSoStores: [],
  filterSoDate: [],
  filterSoKeywords: [],
  soList: [],
  soPage: DEFAULT_VALUE.PAGE,
  soLimit: DEFAULT_VALUE.LIMIT,
  soTotal: 0,
  soListID: '',
  soType: 'filter',
};
const videoSlice = createSlice({
  name: 'video',
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    updateDevice: (state, action) => {
      state.device = action.payload;
    },
    updateStore: (state, action) => {
      state.store = action.payload;
    },
    updateStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    updateDuration: (state, action) => {
      state.duration = action.payload;
    },
    updateDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    setSearchMode: (state, action) => {
      state.searchMode = action.payload;
      state.data = null;
      state.deviceInfo = null;
      state.device = null;
    },
    setSOMode: (state, action) => {
      state.soMode = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phone = action.payload;
    },
    setSODate: (state, action) => {
      state.soDate = action.payload;
    },
    setSOID: (state, action) => {
      state.soID = action.payload;
    },
    setSOCandidates: (state, action) => {
      state.soCandidates = action.payload;
    },
    setCandidate: (state, action) => {
      state.candidate = action.payload;
    },
    setOpenListDevice: (state, action) => {
      state.openListDevice = action.payload;
    },
    changeFilterSoStores: (state, action) => {
      state.filterSoStores = action.payload;
    },
    changeFilterSoDate: (state, action) => {
      state.filterSoDate = action.payload;
    },
    changeFilterSoKeywords: (state, action) => {
      state.filterSoKeywords = action.payload;
    },
    changeSOList: (state, action) => {
      state.soList = action.payload;
    },
    setSOPage: (state, action) => {
      state.soPage = action.payload;
    },
    setSOLimit: (state, action) => {
      state.soLimit = action.payload;
    },
    setSOTotal: (state, action) => {
      state.soTotal = action.payload;
    },
    setSOListID: (state, action) => {
      state.soListID = action.payload;
    },
    setSOType: (state, action) => {
      state.soType = action.payload;
    },
  },
});

export const videoAction = videoSlice.actions;

export default videoSlice.reducer;
