import { LOCAL_STORAGE } from '@/constants/global';
import { buildQueryParams } from '@/utils/buildQueryParams';

import axiosClient from './axiosClient';
import axiosClientV2 from './axiosClientV2';

const AUTH_URL = process.env.REACT_APP_KEYCCLOAK_SERVER_URL;
const REALM = process.env.REACT_APP_KEYCLOAK_REALM;

const authApi = {
  login: (username, password) => {
    const url = `/user-managements/token`;
    const payload = {
      username,
      password,
    };
    return axiosClientV2.post(url, payload);
  },
  refreshToken: (refreshToken) => {
    const url = `/user-managements/token/refresh`;
    return axiosClientV2.post(url, undefined, {
      headers: {
        'Refresh-Token': refreshToken,
      },
    });
  },

  getUser: () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    return axiosClient.get(
      `${AUTH_URL}/realms/${REALM}/protocol/openid-connect/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
  changePassword: (payload) => {
    const url = '/user-managements/users/change-password';
    return axiosClientV2.post(url, payload);
  },

  createUser: (payload) => {
    return axiosClientV2.post('/user-managements/users', payload);
  },

  getListUsers: ({ page, limit, q, status }) => {
    const queryParams = buildQueryParams({
      page,
      limit,
      q,
      status,
    });
    let url = `/user-managements/users${queryParams ? `?${queryParams}` : ''}`;
    return axiosClientV2.get(url);
  },
  updateUser: (userId, payload) => {
    return axiosClientV2.put(`/user-managements/users/${userId}`, payload);
  },

  getUserRecommend: (text) => {
    const url = `/auth/recommend?text=${text}`;
    return axiosClient.get(url);
  },

  resetPassword: (userId, payload) => {
    return axiosClientV2.post(
      `/user-managements/users/${userId}/reset-password`,
      payload
    );
  },

  getRoles: () => {
    return axiosClientV2.get('/user-managements/roles');
  },
};

export default authApi;
