import { Popover, Select } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegUserCircle } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdLogout } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';

import { showConfirmPopup } from '@/components/ui/Notification';
import { LOCAL_STORAGE } from '@/constants/global';
import { useAuthProvider } from '@/contexts/authenticationContext';

import * as styles from './Avatar.module.css';
import ChangePassword from './ChangePassword';

function Avatar() {
  const { i18n } = useTranslation();
  const { currentUser, logout } = useAuthProvider();
  const [openPopover, setOpenPopover] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const username = useMemo(() => currentUser.username, [currentUser.username]);

  const handleVisibleChange = useCallback((visible) => {
    setOpenPopover(visible);
  }, []);

  const handleLogout = useCallback(() => {
    showConfirmPopup('Do you want to logout?', logout);
  }, [logout]);

  const handleOpenChangePassword = useCallback(() => {
    setOpenPopover(false);
    setOpenChangePasswordModal(true);
  }, []);

  const handleChangeLanguage = useCallback(
    (language) => {
      i18n.changeLanguage(language);
      localStorage.setItem(LOCAL_STORAGE.LANGUAGE, language);
    },
    [i18n]
  );

  const extensionContent = (
    <>
      <div className={styles.extension_header}>
        <FaRegUserCircle className={styles.icon_1} />
        <span className={styles.text}>{username}</span>
      </div>
      <div className={styles.extension_main}>
        <a
          className={styles.extension_item}
          href="https://namisense-anywhere.s3.ap-southeast-1.amazonaws.com/public/03._Huong_dan_su_dung_dashboard_v1.0.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <HiOutlineDocumentText className={styles.icon_2} />
          <span className={styles.text_2}>Documentation</span>
        </a>
        <div
          className={styles.extension_item}
          onClick={handleOpenChangePassword}
        >
          <RiLockPasswordLine className={styles.icon_2} />
          <span className={styles.text_2}>Change password</span>
        </div>

        <div className={styles.extension_item} onClick={handleLogout}>
          <MdLogout className={styles.icon_2} />
          <span className={styles.text_2}>Logout</span>
        </div>

        <div className={styles.extension_item}>
          <Select
            className="w-full"
            defaultValue={localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || 'en'}
            onChange={handleChangeLanguage}
            options={[
              {
                label: 'English',
                value: 'en',
              },
              {
                label: 'Tiếng Việt',
                value: 'vi',
              },
            ]}
          />
        </div>
      </div>
    </>
  );

  return (
    <Popover
      content={
        <>
          {extensionContent}
          <ChangePassword
            visible={openChangePasswordModal}
            setVisible={setOpenChangePasswordModal}
          />
        </>
      }
      trigger="hover"
      open={openPopover}
      onOpenChange={handleVisibleChange}
      className={styles.container}
      placement="bottomRight"
    >
      <span>{username[0].toUpperCase()}</span>
    </Popover>
  );
}

export default memo(Avatar);
