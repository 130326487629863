import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  cameraList: [],
  activeCamera: '',
  loading: false,
  total: 0,
};
const cameraSlice = createSlice({
  name: 'camera',
  initialState: initialState,
  reducers: {
    setCameraList: (state, action) => {
      state.cameraList = action.payload;
    },
    setActiveCamera: (state, action) => {
      state.activeCamera = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    addNewData: (state, action) => {
      const tempData = [...state.data];
      tempData.push(action.payload);
      state.data = tempData;

      const total = state.total + 1;
      state.total = total;
    },
    updateCamera: (state, action) => {
      const { cameraId, payload } = action.payload;
      const ids = state.data.map((item) => item.id);
      const idx = ids.indexOf(cameraId);

      if (idx >= 0) {
        const tmpData = [...state.data];
        tmpData[idx] = { ...tmpData[idx], ...payload };
        state.data = tmpData;
      }
    },
  },
});

export const cameraAction = cameraSlice.actions;

export default cameraSlice.reducer;
