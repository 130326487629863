import { jwtDecode } from 'jwt-decode';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import authApi from '@/apis/authentication';
import Loading from '@/components/ui/Loading2';
import { LOCAL_STORAGE } from '@/constants/global';
import { redirectPaths } from '@/routers';

const AuthContext = createContext();

export const useAuthProvider = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN_EXPIRED);
    localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN_EXPIRED);
    localStorage.removeItem(LOCAL_STORAGE.USERNAME_KEY);
    setCurrentUser(null);
    navigate('/login');
  }, [navigate]);

  const getUserClientRole = useCallback(async () => {
    const userInfo = await authApi.getUser();
    // @NOTE: Currently, we only have one role for each user
    return userInfo?.resource_access?.[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]
      ?.roles[0];
  }, []);

  const getUserId = useCallback(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const decodedToken = jwtDecode(accessToken);
    return decodedToken?.sub;
  }, []);

  const refreshAuthToken = useCallback(async () => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);
    const refreshTokenExpiredAt = localStorage.getItem(
      LOCAL_STORAGE.REFRESH_TOKEN_EXPIRED
    );

    if (
      !refreshToken ||
      !refreshTokenExpiredAt ||
      new Date().getTime() > refreshTokenExpiredAt
    ) {
      logout();
      return;
    }

    try {
      const { data } = await authApi.refreshToken(refreshToken);
      if (data && data.accessToken) {
        const {
          accessToken,
          expiresIn,
          refreshToken: newRefreshToken,
          refreshExpiresIn,
        } = data;
        const currentTime = new Date().getTime();
        const accessTokenExpiredAt = currentTime + Number(expiresIn) * 1000;
        const refreshTokenExpiredAt =
          currentTime + Number(refreshExpiresIn) * 1000;

        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, accessToken);
        localStorage.setItem(
          LOCAL_STORAGE.ACCESS_TOKEN_EXPIRED,
          accessTokenExpiredAt
        );
        localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, newRefreshToken);
        localStorage.setItem(
          LOCAL_STORAGE.REFRESH_TOKEN_EXPIRED,
          refreshTokenExpiredAt
        );

        setCurrentUser((prevUser) => ({
          ...prevUser,
          token: accessToken,
          expired: accessTokenExpiredAt,
          refreshToken: newRefreshToken,
          refreshExpired: refreshTokenExpiredAt,
        }));
      } else {
        logout();
      }
    } catch (error) {
      console.error('🚀 ~ Error refreshing token:', error);
      logout();
    }
  }, [logout]);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const expired = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN_EXPIRED);
    const username = localStorage.getItem(LOCAL_STORAGE.USERNAME_KEY);

    if (token && expired && username) {
      if (new Date().getTime() > expired) {
        await refreshAuthToken();
      } else {
        try {
          const userInfo = await authApi.getUser();
          const role = await getUserClientRole();
          const userId = getUserId();
          const isValidToken = userInfo !== null;

          if (isValidToken) {
            setCurrentUser({
              username,
              token,
              expired: parseInt(expired, 10),
              role,
              userId,
            });
          } else {
            logout();
          }
        } catch (err) {
          console.log('🚀 ~ Error fetching user data :', err);
          logout();
        }
      }
    }

    setLoading(false);
  }, [getUserClientRole, getUserId, logout, refreshAuthToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = useCallback(
    async (username, password, complete, onError) => {
      try {
        const { data } = await authApi.login(username, password);
        if (data && data.accessToken) {
          const { accessToken, expiresIn, refreshToken, refreshExpiresIn } =
            data;
          const currentTime = new Date().getTime();
          const accessTokenExpiredAt = currentTime + Number(expiresIn) * 1000;
          const refreshTokenExpiredAt =
            currentTime + Number(refreshExpiresIn) * 1000;

          localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, accessToken);
          localStorage.setItem(
            LOCAL_STORAGE.ACCESS_TOKEN_EXPIRED,
            accessTokenExpiredAt
          );
          localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);
          localStorage.setItem(
            LOCAL_STORAGE.REFRESH_TOKEN_EXPIRED,
            refreshTokenExpiredAt
          );
          localStorage.setItem(LOCAL_STORAGE.USERNAME_KEY, username);

          const role = await getUserClientRole();
          const userId = getUserId();
          setCurrentUser({
            username,
            token: accessToken,
            expired: accessTokenExpiredAt,
            refreshToken,
            refreshExpired: refreshTokenExpiredAt,
            role,
            userId,
          });

          navigate(redirectPaths[role] || redirectPaths.default);

          complete();
        } else if (data && data.is_first) {
          complete(data.is_first, data.username);
        }
      } catch (error) {
        console.error('🚀 ~ Login ~ error:', error);
        complete();
        if (onError) onError(error);
      }
    },
    [getUserClientRole, navigate, getUserId]
  );

  const value = {
    login,
    logout,
    currentUser,
    loading,
    getUserId,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
