import axios from 'axios';
import queryString from 'query-string';

import { LOCAL_STORAGE } from '@/constants/global';

const axiosClientV2 = axios.create({
  baseURL: process.env.REACT_APP_ROOT_API_URL,
  headers: {
    'content-type': 'application/json',
    'x-tenant-id': process.env.REACT_APP_TENANT_ID,
    'x-auth-mechanism': 'keycloak',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClientV2.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosClientV2.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    // Handle errors
    throw error.response.data.error || error.response.data;
  }
);

export default axiosClientV2;
