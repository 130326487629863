import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
};
const userSlice = createSlice({
  name: 'user_management',
  initialState: initialState,
  reducers: {
    updateData: (state, action) => {
      const { data, pagination } = action.payload;
      state.data = data?.map((item) => ({
        ...item,
        key: item.id,
      }));
      state.total = pagination?.total || 0;
    },
    addNewData: (state, action) => {
      const tmpData = [...state.data];
      tmpData.push(action.payload);
      state.data = tmpData;

      let total = state.total + 1;
      state.total = total;
    },
    updateStatus: (state, action) => {
      const userId = action.payload.userId;
      const status = action.payload.status;
      const ids = state.data.map((item) => item._id);
      const idx = ids.indexOf(userId);
      if (idx >= 0) {
        const tmpData = [...state.data];
        tmpData[idx] = { ...tmpData[idx], status: status };
        state.data = tmpData;
      }
    },
    updateStore: (state, action) => {
      const { storeId, payload } = action.payload;
      const ids = state.data.map((item) => item._id);
      const idx = ids.indexOf(storeId);
      if (idx >= 0) {
        const tmpData = [...state.data];
        tmpData[idx] = { ...tmpData[idx], ...payload };
        state.data = tmpData;
      }
    },
  },
});

export const userAction = userSlice.actions;

export default userSlice.reducer;
