import { Form, Input, Modal } from 'antd';
import authApi from 'apis/authentication';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'components/ui/Notification';
import { useAuthProvider } from 'contexts/authenticationContext';
import { useState } from 'react';

import * as styles from './ChangePassword.module.css';

function ChangePassword({ visible, setVisible }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { logout, getUserId } = useAuthProvider();

  const handleClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleSubmitForm = async (values) => {
    const payload = {
      oldPassword: values.old_password,
      newPassword: values.new_password,
    };

    setLoading(true);
    try {
      await authApi.changePassword(payload);
      showSuccessNotification('Change password successfully');
      setVisible(false);
      logout();
    } catch (error) {
      showErrorNotification(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Change password"
      open={visible}
      onOk={handleOk}
      onCancel={handleClose}
      okText="Save"
      width={500}
      okButtonProps={{ loading: loading }}
    >
      <div className={styles.container}>
        <Form
          form={form}
          name="change_password"
          onFinish={handleSubmitForm}
          layout="vertical"
        >
          <Form.Item
            name="old_password"
            label="Old password"
            rules={[
              {
                required: true,
                message: 'Please input old password',
              },
            ]}
          >
            <Input.Password placeholder="Old password" />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="New password"
            rules={[
              {
                required: true,
                message: 'Please input new password',
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters.',
              },
              {
                pattern: new RegExp('^(?=.*[a-z])'),
                message: 'Password must contain at least one lowercase letter.',
              },
              {
                pattern: new RegExp('^(?=.*[A-Z])'),
                message: 'Password must contain at least one uppercase letter.',
              },
              {
                pattern: new RegExp('^(?=.*[0-9])'),
                message: 'Password must contain at least one number.',
              },
              {
                pattern: new RegExp('^(?=.*[!@#$%^&*])'),
                message:
                  'Password must contain at least one special character.',
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="Password confirmation"
            rules={[
              {
                required: true,
                message: 'Please input password confirmation',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      'The password confirmation does not match the new password'
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
          </Form.Item>
        </Form>
        <span className={styles.text_small}>
          After change password, you must re-login
        </span>
      </div>
    </Modal>
  );
}

export default ChangePassword;
