import { LoadingOutlined } from '@ant-design/icons';
import NamiTechLogo from '@public/images/logo.webp';

import * as styles from './style.module.css';

function Loading() {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <LoadingOutlined className={styles.loading_icon} />
        <div className={styles.logo_container}>
          <img
            src={NamiTechLogo}
            className={styles.image}
            alt="NamiTech Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default Loading;
