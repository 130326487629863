import Layout from 'components/layout';
import Loading from 'components/ui/Loading';
import { Role } from 'constants/global';
import { useAuthProvider } from 'contexts/authenticationContext';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// Lazy-loaded components
const Login = lazy(() => import('pages/login'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Store = lazy(() => import('pages/store'));
const StoreDetail = lazy(() => import('pages/store_detail'));
const UserManagement = lazy(() => import('pages/user_management'));
const Playback = lazy(() => import('pages/playback_v2'));
const PlaybackSO = lazy(() => import('pages/playback_v2/so'));
const PlaybackSODetail = lazy(() => import('pages/playback_v2/so/so_detail'));
const PlaybackTime = lazy(() => import('pages/playback_v2/time'));
const PlaybackByCall = lazy(() => import('pages/playback_v2/by_calls'));
const PlaybackByCallDetail = lazy(
  () => import('pages/playback_v2/by_calls/by_calls_detail')
);
const PlaybackByAnalysis = lazy(() => import('pages/playback_v2/by_analysis'));
const PlaybackByAnalysisDetail = lazy(
  () => import('pages/playback_v2/by_analysis/by_analysis_detail')
);
const Dictionary = lazy(() => import('pages/dictionary'));
const TranscriptTasks = lazy(() => import('pages/transcript_tasks'));
const AnalysisHistory = lazy(
  () => import('pages/transcript_tasks/AnalysisHistory')
);
const AnalysisResult = lazy(
  () => import('pages/transcript_tasks/AnalysisResult')
);
const AnalysisPhone = lazy(
  () => import('pages/transcript_tasks/AnalysisPhone')
);
const SaleOrder = lazy(() => import('pages/transcript_tasks/SaleOrder'));
const Keywords = lazy(() => import('pages/keywords'));
const Report = lazy(() => import('pages/report'));
const NotFound = lazy(() => import('pages/not_found'));
const Unauthorized = lazy(() => import('pages/unauthorized'));
const TopicLibraries = lazy(() => import('pages/topic_libraries'));

const routes = [
  { path: '/login', Component: Login },
  { path: '/dashboard', Component: Dashboard },
  { path: '/stores', Component: Store },
  { path: '/stores/:storeId', Component: StoreDetail },
  { path: '/users', Component: UserManagement },
  {
    path: '/playback',
    Component: Playback,
    children: [
      { path: 'sale-order', Component: PlaybackSO },
      { path: 'time', Component: PlaybackTime },
      { path: 'recorded-calls', Component: PlaybackByCall },
      { path: 'analysis', Component: PlaybackByAnalysis },
    ],
  },
  { path: '/playback/sale-order/:soId', Component: PlaybackSODetail },
  {
    path: '/playback/recorded-calls/:callCode',
    Component: PlaybackByCallDetail,
  },
  {
    path: '/playback/analysis/:id',
    Component: PlaybackByAnalysisDetail,
  },
  { path: '/dictionaries', Component: Dictionary },
  {
    path: '/transcript-tasks',
    Component: TranscriptTasks,
    children: [
      { path: 'sale-order', Component: SaleOrder },
      {
        path: 'analysis',
        Component: Outlet,
        children: [
          { path: 'histories', Component: AnalysisHistory },
          { path: 'results', Component: AnalysisResult },
        ],
      },
      { path: 'phones', Component: AnalysisPhone },
    ],
  },
  { path: '/keywords', Component: Keywords },
  { path: '/reports', Component: Report },
  { path: '/not-found', Component: NotFound },
  { path: '/unauthorized', Component: Unauthorized },
  { path: '/topics', Component: TopicLibraries },
];

const validURLs = {
  [Role.ADMIN]: [
    '/login',
    '/dashboard',
    '/stores',
    '/stores/:storeId',
    '/users',
    '/playback',
    '/playback/sale-order',
    '/playback/sale-order/:soId',
    '/playback/time',
    '/playback/recorded-calls',
    '/playback/recorded-calls/:callCode',
    '/playback/analysis',
    '/playback/analysis/:id',
    '/dictionaries',
    '/transcript-tasks',
    '/transcript-tasks/sale-order',
    '/transcript-tasks/analysis-phone',
    '/transcript-tasks/analysis/histories',
    '/transcript-tasks/analysis/results',
    '/keywords',
    '/reports',
    '/not-found',
    '/unauthorized',
    '/topics',
  ],
  [Role.OPERATOR]: [
    '/login',
    '/stores',
    '/stores/:storeId',
    '/playback',
    '/playback/sale-order',
    '/playback/sale-order/:soId',
    '/playback/time',
    '/playback/recorded-calls',
    '/playback/recorded-calls/:callCode',
    '/playback/analysis',
    '/playback/analysis/:id',
    '/transcript-tasks',
    '/transcript-tasks/sale-order',
    '/transcript-tasks/analysis-phone',
    '/transcript-tasks/analysis/histories',
    '/transcript-tasks/analysis/results',
    '/keywords',
    '/not-found',
    '/unauthorized',
  ],
  [Role.ACCOUNT]: ['/login', '/users', '/not-found', '/unauthorized'],
  [Role.QA]: [
    '/login',
    '/playback',
    '/playback/sale-order',
    '/playback/sale-order/:soId',
    '/playback/time',
    '/playback/recorded-calls',
    '/playback/recorded-calls/:callCode',
    '/playback/analysis',
    '/playback/analysis/:id',
    '/transcript-tasks',
    '/transcript-tasks/sale-order',
    '/transcript-tasks/analysis-phone',
    '/transcript-tasks/analysis/histories',
    '/transcript-tasks/analysis/results',
    '/not-found',
    '/unauthorized',
  ],
  [Role.STORE]: [
    '/login',
    '/playback',
    '/playback/sale-order',
    '/playback/sale-order/:soId',
    '/playback/time',
    '/playback/recorded-calls',
    '/playback/recorded-calls/:callCode',
    '/playback/analysis',
    '/playback/analysis/:id',
    '/not-found',
    '/unauthorized',
  ],
};

export const redirectPaths = {
  [Role.ADMIN]: '/dashboard',
  [Role.OPERATOR]: '/playback',
  [Role.ACCOUNT]: '/users',
  [Role.QA]: '/playback',
  [Role.STORE]: '/playback',
  default: '/playback/sale-order',
};

function AppRouter() {
  const { currentUser } = useAuthProvider();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      const { pathname } = location;
      if (pathname === '/') {
        navigate(redirectPaths[currentUser.role] || redirectPaths.default);
      }
    }
  }, [currentUser, location, navigate]);

  return (
    <Routes>
      {currentUser ? (
        <Route path="/" element={<Layout />}>
          {routes
            .filter(({ path }) => validURLs[currentUser.role].includes(path))
            ?.map(({ path, Component, children }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Loading />}>
                    <Component />
                  </Suspense>
                }
              >
                {children &&
                  children.map(
                    ({
                      path: childPath,
                      Component: ChildComponent,
                      children: grandChildren,
                    }) => (
                      <Route
                        key={childPath}
                        path={childPath}
                        element={
                          <Suspense fallback={<Loading />}>
                            <ChildComponent />
                          </Suspense>
                        }
                      >
                        {grandChildren &&
                          grandChildren.map(
                            ({
                              path: grandChildPath,
                              Component: GrandChildComponent,
                            }) => (
                              <Route
                                key={grandChildPath}
                                path={grandChildPath}
                                element={
                                  <Suspense fallback={<Loading />}>
                                    <GrandChildComponent />
                                  </Suspense>
                                }
                              />
                            )
                          )}
                      </Route>
                    )
                  )}
              </Route>
            ))}
          {/* Fallback route to handle unmatched routes */}
          <Route path="*" element={<NotFound />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            }
          />
          {/* Fallback route to handle unmatched routes */}
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
}

export default AppRouter;
